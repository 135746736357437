function passwordGenerator(length, mayus, minus, numbers, symbols) {
  let password = "";
  const chars = [];
  if (mayus) chars.push("ABCDEFGHIJKLMNOPQRSTUVWXYZ");
  if (minus) chars.push("abcdefghijklmnopqrstuvwxyz");
  if (numbers) chars.push("1234567890");
  if (symbols) chars.push("@$!%*?&");

  for (let j = 0; j < chars.length; j++) {
    password += chars[j].charAt(Math.floor(Math.random() * chars[j].length));
  }
  if (length > chars.length) {
    length = length - chars.length;
    for (let i = 0; i < length; i++) {
      const index = Math.floor(Math.random() * chars.length);
      password += chars[index].charAt(
        Math.floor(Math.random() * chars[index].length)
      );
    }
  }
  return password
    .split("")
    .sort(function () {
      return 0.5 - Math.random();
    })
    .join("");
}

export default passwordGenerator;
