import { useState, useEffect } from "react";

import { MdContentCopy } from "react-icons/md";
import passwordGenerator from "./passwordGenerator";

const PassOptions = {
  Upper: "Upper",
  Lower: "Lower",
  Numbers: "Numbers",
  Symbols: "Symbols",
};

const PassGenPage = () => {
  const [passwordOptions, setPasswordOptions] = useState([
    PassOptions.Upper,
    PassOptions.Lower,
    PassOptions.Numbers,
  ]);
  const [passwordLength, setPasswordLength] = useState(12);
  const [copyAnimation, setCopyAnimation] = useState(false);
  const hasUpper = passwordOptions.includes(PassOptions.Upper);
  const hasLower = passwordOptions.includes(PassOptions.Lower);
  const hasNumbers = passwordOptions.includes(PassOptions.Numbers);
  const hasSymbols = passwordOptions.includes(PassOptions.Symbols);
  const [password, setPassword] = useState(
    passwordGenerator(
      passwordLength,
      hasUpper,
      hasLower,
      hasNumbers,
      hasSymbols
    )
  );

  function generatePassword() {
    setPassword(
      passwordGenerator(
        passwordLength,
        hasUpper,
        hasLower,
        hasNumbers,
        hasSymbols
      )
    );
  }

  function copyPassword() {
    setCopyAnimation(true);
    navigator.clipboard.writeText(password);
    setTimeout(() => {
      setCopyAnimation(false);
    }, 500);
  }

  function handleCheckOptions(option) {
    if (passwordOptions.includes(option) && passwordOptions.length > 1) {
      setPasswordOptions((prev) =>
        prev.filter((optionToDel) => optionToDel !== option)
      );
    }
    if (!passwordOptions.includes(option))
      setPasswordOptions((prev) => [...prev, option]);
  }

  function handleSlider(event) {
    setPasswordLength(+event?.target?.value);
  }

  useEffect(() => {
    generatePassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordLength, passwordOptions]);

  return (
    <main className="fixed h-full w-full p-3 flex items-center justify-center bg-background text-primary-dark accent-primary">
      <div className="w-full h-[200px] flex flex-col gap-3 sm:w-[500px]">
        <div className="flex gap-3">
          <button
            className={`${
              copyAnimation ? "animate-bounce" : ""
            } flex justify-between items-center flex-auto p-3 text-xl bg-white font-semibold shadow rounded`}
            onClick={copyPassword}
          >
            <label className="max-w-[190px] overflow-hidden text-ellipsis sm:max-w-none">
              {password}
            </label>
            <MdContentCopy size="20" className="cursor-pointer" />
          </button>
          <button
            className="p-3 bg-primary text-primary-white hover:bg-primary-dark shadow rounded"
            onClick={generatePassword}
          >
            Generate
          </button>
        </div>
        <div className="flex flex-col justify-between bg-white p-3 gap-4 shadow rounded">
          <div className="h-9 flex items-center gap-3 text-xl">
            <div className="w-9 flex items-center justify-center border border-gray-300 rounded cursor-default">
              {passwordLength}
            </div>
            <input
              type="range"
              min="4"
              max="32"
              value={passwordLength}
              onChange={handleSlider}
              className="w-full"
            />
          </div>
          <div className="border-t border-gray" />
          <div className="flex justify-center gap-6">
            <button
              onClick={() => handleCheckOptions(PassOptions.Upper)}
              className="flex items-center gap-2 cursor-pointer"
            >
              <input
                type="checkbox"
                id="upper"
                name="upper"
                value="upper"
                checked={passwordOptions.includes(PassOptions.Upper)}
                readOnly
              />
              <span>ABC</span>
            </button>
            <button
              onClick={() => handleCheckOptions(PassOptions.Lower)}
              className="flex items-center gap-2 cursor-pointer"
            >
              <input
                type="checkbox"
                id="lower"
                name="lower"
                value="lower"
                checked={passwordOptions.includes(PassOptions.Lower)}
                readOnly
              />
              <span>abc</span>
            </button>
            <button
              onClick={() => handleCheckOptions(PassOptions.Numbers)}
              className="flex items-center gap-2 cursor-pointer"
            >
              <input
                type="checkbox"
                id="number"
                name="number"
                value="number"
                checked={passwordOptions.includes(PassOptions.Numbers)}
                readOnly
              />
              <span>123</span>
            </button>
            <button
              onClick={() => handleCheckOptions(PassOptions.Symbols)}
              className="flex items-center gap-2 cursor-pointer"
            >
              <input
                type="checkbox"
                id="symbols"
                name="symbols"
                value="symbols"
                checked={passwordOptions.includes(PassOptions.Symbols)}
                readOnly
              />
              <span>#$%</span>
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default PassGenPage;
